import { type EmployerFeature, FeatureName, FeatureStatus } from '@factoryfixinc/ats-interfaces';
import { EmployerFeaturePersistence } from './employer-feature.persistence';
import { useEmployerFeatureStore } from './employer-feature.store';

export class EmployerFeatureService {
  private persistence = new EmployerFeaturePersistence();
  private store = useEmployerFeatureStore();

  public get hasCopilotOutreachEnabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.COPILOT_OUTREACH &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public get hasSubscriptionV2Enabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.SUBSCRIPTION_V2 &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public get hasUrlScreeningEnabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.URL_SCREENING &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public get hasZeroScreeningEnabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.ZERO_SCREENING &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public async fetchEmployerFeatures(): Promise<EmployerFeature[]> {
    const employerFeatures = await this.persistence.fetchEmployerFeatures();
    this.store.employerFeatures = employerFeatures;

    return employerFeatures;
  }
}
